import Pristine from 'pristinejs';
import axios from 'axios';
import serialize from './serialize';

let defaultConfig = {
	// class of the parent element where the error/success class is added
	classTo: 'form-group',
	errorClass: 'has-danger',
	successClass: 'has-success',
	// class of the parent element where error text element is appended
	errorTextParent: 'form-group',
	// type of element to create for the error text
	errorTextTag: 'div',
	// class of the error text element
	errorTextClass: 'text-help',
};

let pristine;
const form = document.getElementsByClassName('js-newsletter-form')[0];
const fields = document.getElementsByClassName('js-form-field');
const submitButton = document.getElementsByClassName('js-newsletter-submit')[0];
const successMessage = document.getElementsByClassName('js-form-success')[0];
const failedMessage = document.getElementsByClassName('js-form-failure')[0];
const loader = document.getElementsByClassName('js-loader')[0];

const scrollToTop = () => {
	const c = document.documentElement.scrollTop || document.body.scrollTop;
	if (c > 0) {
		window.requestAnimationFrame(scrollToTop);
		window.scrollTo(0, c - c / 8);
	}
};

function afterSubmit(error) {
	loader.style.display = 'none';
	if (error) {
		failedMessage.style.display = 'block';
		return;
	}
	// scrollToTop();
	form.remove();
	successMessage.style.display = 'block';
	failedMessage.style.display = 'none';
}

function handleSubmit() {
	form.addEventListener('submit', (e) => {
		e.preventDefault();
		if (pristine.validate()) {
			const data = serialize(form);
			loader.style.display = 'block';
			axios({
				method: 'post',
				url: '/.netlify/functions/emailSubscribe',
				data,
			})
				.then(() => afterSubmit())
				.catch((error) => afterSubmit(error));
		}
	});
}

if (form && fields.length > 0 && submitButton) {
	pristine = new Pristine(form, defaultConfig, false);
	handleSubmit();
}
